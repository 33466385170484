<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { CargillCrudMetaView } from '@cargill/shared'
import service from '../api/logisticPortalDomesticMarketService'
import StuffingPortalStatusCellRenderer from '../components/stuffing-portal-table/StuffingPortalStatusCellRenderer'
import Vue from 'vue'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service
    }
  },
  methods: {
    async getMeta() {
      const meta = await service.getMetaWithValidation()

      const chargingStatus = meta.fields.find((x) => x.id == 'chargingStatus')
      delete chargingStatus.cellRenderer
      chargingStatus.cellRendererFramework = Vue.extend(
        StuffingPortalStatusCellRenderer
      )

      const unloadingStatus = meta.fields.find((x) => x.id == 'unloadingStatus')
      delete unloadingStatus.cellRenderer
      unloadingStatus.cellRendererFramework = Vue.extend(
        StuffingPortalStatusCellRenderer
      )
      return meta
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
